.vendar-gallery .image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: 3px solid #01525f;
}

.vendar-gallery .image-gallery-thumbnail {
  border-radius: 7px;
  width: fit-content;
  border: 1px solid rgba(124, 124, 132, 0.5);
  margin: 0 5px;
}

.vendar-gallery .image-gallery-thumbnails-container {
  padding-top: 15px;
}

.vendar-gallery .image-gallery-image {
  border-radius: 10px;
  object-fit: cover;
}

.vendar-gallery .fullscreen .image-gallery-image {
  object-fit: contain;
}

.vendar-gallery.image-gallery.fullscreen-modal {
  z-index: 2000;
}
