.device-iphone-x {
  height: 694px !important;
  width: 342px !important;
  margin: 0 auto !important;
}

.device-iphone-x .device-frame {
  height: 694px !important;
  width: 342px !important;
  padding: 22px !important;
}

.device-iphone-x .device-header {
  top: 22px !important;
}

@media only screen and (max-width: 350px) {
  .device-iphone-x {
    width: 100% !important;
  }

  .device-iphone-x .device-frame {
    width: 100% !important;
  }
}

.device .device-frame img {
  max-width: 100%;
  -webkit-font-smoothing: antialised;
  height: 645px;
  backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
}

.device-iphone-x .device-header {
  z-index: 1;
}

.device-iphone-x .device-sensors::before,
.device-iphone-x .device-sensors::after {
  z-index: 2;
}
