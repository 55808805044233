.Vendar__effect {
  position: relative;
}

.Vendar__effect::after {
  content: "";
  position: absolute;
  bottom: -0.125rem;
  left: -0.5rem;
  right: -0.5rem;
  height: 0.75rem;
  z-index: -1;
  background-image: url("../../assets/line.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
