.vendar-phone-input {
  box-shadow: none !important;
}

.react-tel-input .selected-flag.open:before {
  box-shadow: none !important;
  border: none !important;
}

.vendar-phone-input:focus {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #01525f !important;
  background: rgba(243, 244, 246, 0.7) !important;
}
.vendar-phone-input:disabled {
  background: #f4f7fd !important;
}
.vendar-phone-input::placeholder {
  color: rgba(107, 114, 128, 0.3) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}
.vendar-phone-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(107, 114, 128, 0.3) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.vendar-phone-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(107, 114, 128, 0.3) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

@media only screen and (max-width: 500px) {
  .vendar-phone-input::placeholder,
  .vendar-phone-input::-ms-input-placeholder,
  .vendar-phone-input:-ms-input-placeholder {
    font-size: 12px !important;
  }
}
